
<template v-slot:body>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col lg="12" sm="12">
        <div class="row">
          <div class="col-sm-12 text-center">
            <b-button type="button" variant="primary" class="mr-2" @click="printID">{{ $t('globalTrans.print') }}</b-button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
        <div id="printMe" >
          <div id="print-area" style="float: left; border: 3px solid #6578B5 !important; box-sizing: border-box; color: rgb(66, 66, 66); height: 420px; width: 250px;">
            <table style="width: 100%; margin-top: 10px;">
              <tbody>
                <tr>
                  <td colspan="2" style="text-align: center !important;">
                    <div style="font-size: 16px; color: #6578B5; line-height: 32px;">
                      <b>‘{{ currentLocale === 'en' ? formData.training_title : formData.training_title_bn }}’
                      </b>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="text-align: center !important;">
                    <div style="font-size: 13px; line-height: 26px;">
                      <b>{{ date }}</b>
                    </div>
                  </td>
                </tr>
                <tr>
                    <td colspan="2" style="text-align: center !important;">
                      <div class="mt-3">
                        <img :src="formData.image ? trainingElearningServiceBaseUrl + 'storage/' + formData.image : 'https://via.placeholder.com/720.png'" class="mx-auto d-block border border-dark mb-3" alt="Profile Image" width="80">
                      </div>
                    </td>
                  </tr>
                <tr>
                  <td colspan="2" style="text-align: center !important;height: 101px;">
                    <div style="color: #E74E16;margin-bottom: 0px;font-size: 14px;padding: 0px;margin-top: 22px;line-height: 20px;">{{ currentLocale === 'en' ? formData.name : formData.name_bn }}</div>
                    <div style="color: #7EC565; font-size: 14px; padding: 0px; line-height: 22px;">{{ currentLocale === 'en' ? formData.designation_en : formData.designation_bn }}</div>
                    <div style="color: #7EC565; font-size: 14px; padding: 0px; line-height: 22px;margin-bottom: 31px;">{{ currentLocale === 'en' ? formData.office : formData.office_bn }}, {{ currentLocale === 'en' ? formData.address : formData.address_bn }}</div>
                    <br>
                  </td>
                </tr>
                <tr style="background-color: rgb(173, 177, 177);">
                  <td colspan="2">
                    <div style="float: left; width: 8%;">
                      <img :src="trainingElearningServiceBaseUrl + 'storage/' + formData.right_logo" height="22px" width="22px" style="border-radius: 50% !important;">
                    </div>
                    <div style="float: left; width: 92%;">
                      <div style="font-size: 8px; line-height: 25px;letter-spacing:-.3px;">
                        <b class="p-1">
                          {{ currentLocale === 'en' ? formData.org : formData.org_bn }},
                          {{ currentLocale === 'en' ? formData.org_address : formData.org_address_bn }}
                          </b>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
          </div>
        </div>
        <!-- <div id="printMe" >
          <div id="print-area" style="float: left; border: 3px solid #6578B5 !important; box-sizing: border-box; color: rgb(66, 66, 66); height: 300px; width: 250px;">
            <table style="width: 100%; margin-top: 10px;">
              <tbody>
                <tr>
                  <td colspan="2" style="text-align: center !important;">
                    <div style="font-size: 16px; color: #6578B5; line-height: 32px;">
                      <b>‘{{ currentLocale === 'en' ? formData.training_title : formData.training_title_bn }}’
                      </b>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="text-align: center !important;">
                    <div style="font-size: 13px; line-height: 26px;">
                      <b>{{ date }}</b>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="text-align: center !important;height: 101px;">
                    <div style="color: #E74E16;margin-bottom: 0px;font-size: 14px;padding: 0px;margin-top: 22px;line-height: 20px;">{{ currentLocale === 'en' ? formData.name : formData.name_bn }}</div>
                    <div style="color: #7EC565; font-size: 14px; padding: 0px; line-height: 22px;">{{ currentLocale === 'en' ? formData.designation : formData.designation_bn }}</div>
                    <div style="color: #7EC565; font-size: 14px; padding: 0px; line-height: 22px;margin-bottom: 31px;">{{ currentLocale === 'en' ? formData.office : formData.office_bn }}, {{ currentLocale === 'en' ? formData.address : formData.address_bn }}</div>
                    <br>
                  </td>
                </tr>
                <tr style="background-color: rgb(173, 177, 177);">
                  <td colspan="2">
                    <div style="float: left; width: 8%;">
                      <img :src="trainingElearningServiceBaseUrl + 'storage/' + formData.right_logo" height="22px" width="22px" style="border-radius: 50% !important;">
                    </div>
                    <div style="float: left; width: 92%;">
                      <div style="font-size: 8px; line-height: 25px;letter-spacing:-.3px;">
                        <b>
                          {{ currentLocale === 'en' ? formData.org : formData.org_bn }},
                          {{ currentLocale === 'en' ? formData.org_address : formData.org_address_bn }}
                          </b>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->
        <!-- <div class="row">
          <div class="col-sm-12 text-center">
            <b-button type="button" variant="primary" class="mr-2" @click="printID">{{ $t('globalTrans.print') }}</b-button>
          </div>
        </div> -->
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>

import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import VueHtmlToPaper from 'vue-html-to-paper'
import Vue from 'vue'

const options = {
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=no'
  ],
  autoClose: true
}
Vue.use(VueHtmlToPaper, options)

export default {
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      const startDate = this.formData.training_start_date.split('-')
      const endDate = this.formData.training_end_date.split('-')
      let startMonthId = ''
      let endMonthId = ''
      startMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(startDate[1]))
      endMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(endDate[1]))
      if (startDate[1] === endDate[1]) {
        this.date = this.$n(startDate[2]) + ' - ' + this.$n(endDate[2]) + ' ' + (this.currentLocale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ',' + this.$n(startDate[0], { useGrouping: false })
      } else {
        this.date = this.$n(startDate[2]) + ' ' + (this.currentLocale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ' - ' + this.$n(endDate[2]) + ' ' + (this.currentLocale === 'en' ? endMonthId.text_en : endMonthId.text_bn) + ',' + this.$n(startDate[0], { useGrouping: false })
      }
    }
  },
  mounted () {
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {},
      date: '',
      isLoading: false,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    },
    designationList () {
      return this.$store.state.commonObj.designationList.filter(item => item.status === 0)
    }
  },
  watch: {
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    printID () {
      this.$htmlToPaper('printMe')
    }
  }
}
</script>
<style>
@import url(//db.onlinewebfonts.com/c/46aebfd06fd610b5813d2d877dc23398?family=Tilda+Script);
@import url(//db.onlinewebfonts.com/c/09400bc4b89c1605f1ccd16c0c305a8c?family=Lateef);
@import url(//db.onlinewebfonts.com/c/6d36b202b654e70a446767b843911eea?family=Sexything);
</style>
