import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      // const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
        {
          text: reportTitle,
          style: 'krishi',
          alignment: 'center'
        }
      ]
      let allRows = []
      allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.name'), style: 'th', alignment: 'center' },
          { text: vm.$t('elearning_config.organization'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.designation'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.mobile'), style: 'th', alignment: 'center' },
          { text: vm.$t('elearning_tpm.working_field'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.status'), style: 'th', alignment: 'center' }
        ]
      ]
      // table body
      if (vm.$store.state.list.length) {
        vm.$store.state.list.filter((item, index) => {
           const rowItem = [
             { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
             { text: i18n.locale === 'en' ? item.name : item.name_bn, style: 'td', alignment: 'center' },
             { text: i18n.locale === 'en' ? item.org : item.org_bn, style: 'td', alignment: 'center' },
             { text: i18n.locale === 'en' ? item.designation : item.designation_bn, style: 'td', alignment: 'center' },
             { text: item.mobile, style: 'td', alignment: 'center' },
             { text: i18n.locale === 'en' ? item.working_field : item.working_field_bn, style: 'td', alignment: 'center' },
             { text: item.status === 1 ? vm.$t('globalTrans.active') : vm.$t('globalTrans.inactive'), style: 'td', alignment: 'center' }
           ]
           allRows.push(rowItem)
        })
      } else {
        const rowItem = [
          { text: vm.$t('globalTrans.noDataFound'), style: 'td', alignment: 'center', colSpan: 7 },
          { },
          { },
          { },
          { },
          { },
          { }
        ]
        allRows.push(rowItem)
      }
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: '*',
          body: allRows
        }
      })
      const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: 6,
            margin: [1, 1, 1, 1]
          },
          td: {
            fontSize: 5.5,
            margin: [1, 1, 1, 1]
          },
          krishi: {
            margin: [0, 1, 0, 15],
            alignment: 'center'
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
