<template>
  <div class="inner-section">
         <card>
          <template v-slot:searchHeaderTitle>
            <h4 class="card-title">{{$t('elearning_tim.id_card')}}</h4>
          </template>
            <template v-slot:searchBody>
                <b-row>
                  <b-col lg="6" sm="12">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="circular_memo_no"
                    >
                    <template v-slot:label>
                      {{$t('elearning_iabm.circular_memo_no')}}
                    </template>
                    <b-form-input
                      id="circular_memo_no"
                      v-model="search.circular_memo_no"
                    ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_type_id"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_type')}}
                      </template>
                      <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_category_id"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_category')}}
                      </template>
                      <b-form-select
                          plain
                          v-model="search.training_category_id"
                          :options="trainingCategoryList"
                          id="training_category_id"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_title_id"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_title')}}
                      </template>
                      <b-form-select
                          plain
                          v-model="search.training_title_id"
                          :options="trainingTitleList"
                          id="training_title_id"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" sm="12" class="text-right">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
            </template>
        </card>
        <body-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('elearning_tim.id_card')}}</h4>
          </template>
          <template v-slot:headerAction>
              <b-button variant="primary btn_add_new" @click="pdfExport" class="mr-2">
                <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
              </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col sm="12">
                  <div class="quick-filter-wrapper">
                    <div class="quick-filter-left">
                      <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                        <b-dropdown-form>
                          <div
                            class="form-group"
                            v-for="(field, index) in labelData"
                            :key="index"
                            >
                            <b-form-checkbox
                              :id="'checkbox-' + field.label"
                              v-model="field.show"
                              :name="'checkbox-' + field.label"
                              value=1
                              unchecked-value=0
                            >
                            {{ ($i18n.locale==='bn') ? field.label : field.label }}
                            </b-form-checkbox>
                          </div>
                        </b-dropdown-form>
                      </b-dropdown>
                    </div>
                    <div class="quick-filter-right">
                      <b-form-group
                        :label="$t('menu.perpage')"
                        label-for="per-page-select"
                      >
                      <b-form-select
                        id="per-page-select"
                        v-model="search.limit"
                        :options="pageOptions"
                        size="sm"
                      ></b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="table-responsive">
                  <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                    <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                      <template v-slot:cell(serial)="data">
                        {{ $n(data.item.serial + pagination.slOffset) }}
                      </template>
                      <template v-slot:cell(action)="data">
                        <a href="javascript:" v-b-modal.modal-4 class="btn_table_action table_action_view" size="sm" @click="edit(data.item)"><i class="fas fa-print"></i></a>
                      </template>
                    </b-table>
                  </slot>
                  <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                    <b-pagination
                      v-model="pagination.currentPage"
                      :perPage="search.limit"
                      :total-rows="pagination.totalRows"
                      @input="searchData"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </body-card>
        <b-modal id="modal-4" size="sm" hide-footer :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </div>
</template>
<script>

import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { traineeIdCards } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import ExportPdf from './export_pdf_details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form
    },
    data () {
      return {
        sortBy: '',
        sortDirection: '',
        sortDesc: '',
        search: {
          circular_memo_no: '',
          org_id: 0,
          training_category_id: 0,
          training_type_id: 0,
          training_title_id: 0,
          limit: 30
        },
        trainingCategoryList: [],
        trainingTitleList: [],
        labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
          { labels: 'elearning_iabm.registration_for', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '30%' } },
          { labels: 'elearning_config.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '35%' } },
          { labels: 'globalTrans.office', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '35%' } },
          { labels: 'elearning_config.training_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '35%' } },
          { labels: 'globalTrans.name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } },
          { labels: 'globalTrans.mobile', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
          { labels: 'globalTrans.print', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } }
        ]
      }
    },
    computed: {
      userTypeList () {
        const userList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
        ]
        return userList
      },
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      },
      formTitle () {
        return this.$t('elearning_tim.id_card')
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
        let keys = []
        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'serial' },
            { key: 'user_type_name_bn' },
            { key: 'org_bn' },
            { key: 'office_bn' },
            { key: 'training_title_bn' },
            { key: 'name_bn' },
            { key: 'mobile' },
            { key: 'action' }
          ]
        } else {
          keys = [
          { key: 'serial' },
          { key: 'user_type_name' },
          { key: 'org' },
          { key: 'office' },
          { key: 'training_title' },
          { key: 'name' },
          { key: 'mobile' },
          { key: 'action' }
          ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      pageOptions () {
        return this.$store.state.commonObj.pageOptions
      }
    },
    watch: {
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    async created () {
        /* this.search = Object.assign({}, this.search, {
            org_id: this.$store.state.dataFilters.orgId
        }) */
        this.loadData()
    },
    methods: {
      async searchData () {
        this.loadData()
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, traineeIdCards, params).then(response => {
          if (response.success) {
              this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
              this.paginationData(response.data, this.search.limit)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      getCustomDataList (data) {
        const listData = data.map((item, index) => {
          const customItem = {}
          const userTypeObj = this.userTypeList.find(userType => userType.value === item.registration_for)
          if (typeof userTypeObj !== 'undefined') {
            customItem.user_type_name = userTypeObj.text_en
            customItem.user_type_name_bn = userTypeObj.text_bn
          } else {
            customItem.user_type_name = ''
            customItem.user_type_name_bn = ''
          }
          const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(item.organization_id))
          const orgReportHeadObj = this.$store.state.TrainingElearning.commonObj.trainingReportHeadList.find(doc => doc.org_id === parseInt(item.organization_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
            orgData.org_address = orgReportHeadObj.address
            orgData.org_address_bn = orgReportHeadObj.address_bn
            orgData.right_logo = orgReportHeadObj.right_logo
          } else {
            orgData.org = ''
            orgData.org_bn = ''
            orgData.org_address = ''
            orgData.org_address_bn = ''
            orgData.right_logo = ''
          }
          const officeObj = this.$store.state.CommonService.commonObj.officeList.find(office => office.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
            officeData.address = officeObj.address
            officeData.address_bn = officeObj.address_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
            officeData.address = ''
            officeData.address_bn = ''
          }
          const designationObj = this.$store.state.CommonService.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          const designationData = {}
          if (typeof designationObj !== 'undefined') {
            designationData.designation = designationObj.text_en
            designationData.designation_bn = designationObj.text_bn
          } else {
            designationData.designation = ''
            designationData.designation_bn = ''
          }
          const trainingTypeListObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(obj => obj.value === item.training_type_id)
          const trainingTypeData = {
            training_type: trainingTypeListObj?.text_en,
            training_type_bn: trainingTypeListObj?.text_bn
          }
          const trainingTitleListObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(obj => obj.value === item.training_title_id)
          const trainingTitleData = {
            training_title: trainingTitleListObj?.text_en,
            training_title_bn: trainingTitleListObj?.text_bn
          }
          return Object.assign({}, { serial: index }, item, orgData, officeData, designationData, trainingTypeData, trainingTitleData, customItem)
        })
        return listData
      },
      pdfExport () {
        const reportTitle = this.$i18n.locale === 'en' ? 'Trainer Profiles' : 'প্রশিক্ষকের প্রোফাইল'
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/fertilizer/config/report-heading/detail', 12, reportTitle, this)
      }
    }
}
</script>
